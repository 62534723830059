<template>
  <div class="order-state container text-center">
    <template v-if="!failed">
      <img src="../../../static/images/payment/info-success.svg"
           alt="paymentSuccess">
      <p v-if="isGroup">支付成功，正在进入当前团购</p>
      <p v-else>支付成功，书籍正在进入自动打印</p>
      <p>
        <template v-if="isGroup">
          <router-link :to="{name: 'group-start'}">
            <button class="btn primary">查看团购</button>
          </router-link>
          <a href="https://m.me/ipastbook?ref=groupbuying">
            <button class="btn red">聯繫我們</button>
          </a>
        </template>
        <template v-else>
          <button class="btn default" @click="FBInvite($store.state.user.uid)" v-show="false">分享訂單，獲取獎勵</button>
          <router-link :to="{name: 'order-list'}">
            <button class="btn primary">查看訂單</button>
          </router-link>
        </template>
      </p>
    </template>
    <template v-else>
      <img src="../../../static/images/payment/info-error.svg">
      <p>支付失敗</p>
      <p>
        <template v-if="isGroup">
          <router-link to="/group/start">
            <button class="btn default">返回团购</button>
          </router-link>
          <a href="https://m.me/ipastbook">
            <button class="btn red">聯繫我們</button>
          </a>
        </template>
        <template v-else>
          <router-link :to="{name: 'shelf'}">
            <button class="btn default">返回書架</button>
          </router-link>
          <button class="btn primary"
                  @click="$router.push({name: 'order-check', query: {bid:  order.bid, binding: order.binding, quantity: order.quantity}})">
            嘗試再次支付
          </button>
        </template>
      </p>
    </template>
  </div>
</template>

<script>
import api from 'src/api'
import { mapActions } from 'vuex'
import FBShare from 'src/mixins/FBShare'
export default {
  name: 'order-status',
  mixins: [FBShare],
  data () {
    return {
      orderLoaded: false,
      order: {},
      failed: false,
      isGroup: false
    }
  },
  async created () {
    const { failed, type } = this.$route.query
    const orderId = this.$route.query.order_id
    const gid = this.$route.query.group_oid
    if (type !== 'stripe' && orderId) {
      await this.fetchOrderStatus(orderId)
      return
    } else if (gid) {
      await this.fetchGroupOrder(gid)
    }
    this.failed = failed || this.failed
  },
  methods: {
    ...mapActions([
      'setLoading'
    ]),
    fetchGroupOrder (oid) {
      this.isGroup = true
      return api.fetchGroupCheckout(oid).then(data => {
        if (!data || data.errors) {
          const msg = data.errors ? `- ${data.errors.code}` : ''
          this.$toast({
            message: `數據請求錯誤<br>請聯繫客服 錯誤碼 ${msg}`
          })
          return
        }

        this.orderLoaded = true

        if (data.paid) {
          this.$toast({
            message: '已支付',
            icon: 'fa-check-circle',
            duration: 1000
          })
          window.fbq('track', 'Purchase', {
            content_name: 'group coupon',
            content_category: 'group coupon ',
            content_ids: [data.groupId],
            content_type: 'product',
            value: data.paidMoney,
            currency: data.currency
          })

          window.ga('send', {
            hitType: 'event',
            eventCategory: 'purchase',
            eventAction: 'purchase group coupons',
            eventLabel: 'purchase page'
          })
        } else {
          this.$toast({
            message: '支付失敗',
            icon: 'fa-frown-o',
            duration: 1000
          })
          this.failed = true

          window.ga('send', {
            hitType: 'event',
            eventCategory: 'Error',
            eventAction: `Payment failed - groupInfo: ${data.groupId} - ${data.payMethod}`,
            eventLabel: 'Payment-Error'
          })
        }
      })
    },
    fetchOrderStatus (orderId) {
      return api.fetchOrder(orderId).then(data => {
        this.setLoading(false)
        this.orderLoaded = true
        this.order = data
        if (data.paid) {
          // 支付成功
          window.ga('ecommerce:addItem', {
            id: data.orderId,
            name: data.bookName,
            category: data.binding,
            quantity: data.quantity,
            currency: data.currency
          })
          window.ga('ecommerce:send')
        }
        return data
      })
    }
  }
}
</script>

<style type="text/scss" lang="scss">

</style>
